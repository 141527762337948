@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* --background: 0 0% 7%;
    --background-emphasis: 0 0% 10%;
    --background-subtle: 0 0% 15%;
    --background-muted: 0 0% 17%;

    --foreground: 0 0% 100%;
    --primary: 30 96% 44%;
    --danger: 0 75% 41%; */
  }

  .dark {
    /* --background: 195 82% 4%;
    --background-lighter: 195 82% 15%;
    --background-darker: 195 82% 0%;
    --background-hover: 195 82% 10%;

    --foreground: 195 0% 98%;

    --border: 195 82% 15%; */
  }
}
